import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 18 12"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M17.052 5c.521 0 .948.427.948.949v.102a.95.95 0 0 1-.948.949H.948A.95.95 0 0 1 0 6.051v-.102A.95.95 0 0 1 .948 5zm0 5c.521 0 .948.427.948.949v.102a.95.95 0 0 1-.948.949H.948A.95.95 0 0 1 0 11.051v-.102A.95.95 0 0 1 .948 10zm0-10c.521 0 .948.427.948.949v.102a.95.95 0 0 1-.948.949H.948A.95.95 0 0 1 0 1.051V.949A.95.95 0 0 1 .948 0z"
    }, null, -1)
  ])))
}
export default { render: render }