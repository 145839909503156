import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M20 11a1 1 0 0 1 1 1v6c0 1.654-1.346 3-3 3H6c-1.654 0-3-1.346-3-3V6c0-1.654 1.346-3 3-3h6a1 1 0 1 1 0 2H6c-.552 0-1 .449-1 1v12c0 .551.448 1 1 1h12c.552 0 1-.449 1-1v-6a1 1 0 0 1 1-1m-4.005-6A1 1 0 0 1 16 3h.005l3.997.02a1 1 0 0 1 .995.999L21 7.999a1 1 0 1 1-2 .002V6.415l-6.293 6.292a.997.997 0 0 1-1.414 0 1 1 0 0 1 0-1.414l6.285-6.285z"
    }, null, -1)
  ])))
}
export default { render: render }