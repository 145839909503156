import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m13.349 8.899-2.246-2.246 1.623-1.624 2.245 2.245zm-5.783 5.788-2.48.226.22-2.463 4.68-4.681 2.247 2.247zm8.604-8.572-.001-.001-2.282-2.282c-.617-.616-1.678-.645-2.263-.057L4.127 11.27c-.272.271-.44.631-.475 1.012l-.316 3.475a.835.835 0 0 0 .906.905l3.475-.316c.382-.035.74-.202 1.012-.473l7.497-7.498c.607-.608.581-1.623-.056-2.261Z"
    }, null, -1)
  ])))
}
export default { render: render }