<template>
  <div class="flex h-screen flex-col items-stretch justify-stretch">
    <!-- Header -->
    <header
      class="z-30 flex min-h-[64px] items-center gap-2 bg-white px-4 py-2 shadow-default"
    >
      <button
        class="mr-1 p-2 hover:bg-gray-100 rounded-full"
        @click="sidebar.toggleOpen"
      >
        <BurgerMenu class="h-5 w-5" />
      </button>

      <NuxtLink
        to="/"
        class="mr-auto my-2 flex items-center"
        title="Rubix Monitor"
      >
        <LogoProd
          v-if="config.public.appContext === 'production'"
          class="h-6"
        />
        <LogoDev v-else class="h-6" />
      </NuxtLink>
      <UiHeaderHelpMenu class="hidden sm:block" />
      <UiTooltip
        v-if="$auth.currentUser.value"
        class="hidden sm:block"
        content="Einstellungen"
      >
        <NuxtLink to="/settings">
          <UiIconButton
            icon-name="settings"
            :background-color="isSettingsPageOpen ? 'blue' : 'none'"
            :icon-color="isSettingsPageOpen ? 'white' : 'gray'"
            size="medium"
            title="Einstellungen"
          />
        </NuxtLink>
      </UiTooltip>
      <HeaderUser />
    </header>
    <div class="flex min-h-0 min-w-0 grow flex-col sm:flex-row">
      <div v-if="!sidebar.open.value" class="min-w-[64px]" />
      <nav
        ref="navBarRef"
        :data-open="sidebar.open.value"
        :data-hover="isHover && !sidebar.open.value"
        :class="
          cn(
            'z-40 absolute hidden flex-col w-[64px] h-[calc(100vh-64px)] gap-1 bg-white p-2 transition-width shadow-md sm:flex data-[open=true]:flex data-[open=true]:w-64 data-[open=true]:sm:relative data-[hover=true]:w-64 sm:z-10',
            {
              'inset-0': isMaxSm
            }
          )
        "
      >
        <button
          class="mx-2 my-1.5 p-2 bg-gray-100 sm:hidden rounded-full w-fit"
          @click="sidebar.toggleOpen"
        >
          <BurgerMenu class="h-5 w-5" />
        </button>
        <a
          :href="config.public.editorLink"
          :data-open="sidebar.open.value"
          target="_blank"
          class="flex items-center rounded-full p-2 gap-4 hover:bg-gray-100 font-semibold text-gray-800 text-sm data-[open=true]:w-60"
        >
          <PencilIcon class="h-6 w-6 shrink-0 m-1" />
          <span class="overflow-x-hidden">Editor</span>
          <ExternalLinkIcon class="h-4 w-4 text-gray-500 overflow-x-hidden" />
        </a>
        <UiHeaderHelpMenu
          class="flex justify-start ml-1 rounded-full p-2 sm:hidden hover:bg-gray-100 font-semibold text-gray-800 text-sm"
          :with-label="true"
          data-testid="test-help-link"
          @redirect="sidebar.toggleOpen"
        />
        <NuxtLink
          to="/settings"
          class="flex items-center justify-start gap-4 rounded-full p-2 hover:bg-gray-100 sm:hidden font-semibold text-gray-800 text-sm"
          data-testid="test-settings-link"
          @click="sidebar.toggleOpen"
        >
          <UiIconButton
            icon-name="settings"
            :background-color="isSettingsPageOpen ? 'blue' : 'none'"
            :icon-color="isSettingsPageOpen ? 'white' : 'gray'"
            size="medium"
            title="Einstellungen"
          />
          <span>Einstellungen</span>
        </NuxtLink>
      </nav>
      <Transition name="fade">
        <div
          v-if="sidebar.open.value"
          class="absolute inset-0 z-30 bg-black/50 sm:hidden"
          @click="sidebar.toggleOpen"
        />
      </Transition>
      <main class="flex min-h-0 min-w-0 grow">
        <slot />
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UiIconButton } from '@dpa-id-components/dpa-shared-components'
import PencilIcon from '@/assets/icons/pencil.svg?component'
import ExternalLinkIcon from '@/assets/icons/external-link.svg?component'
import HeaderUser from '~/components/ui/header/HeaderUser.vue'
import LogoProd from '@/assets/icons/logo-prod.svg?component'
import LogoDev from '@/assets/icons/logo-dev.svg?component'
import BurgerMenu from '@/assets/icons/burger-menu.svg?component'
import useStaggeredHover from '~/composables/use-staggered-hover'
import { ref } from 'vue'
import type { MaybeElement } from '@vueuse/core'

const config = useRuntimeConfig()
const route = useRoute()
const { $auth } = useNuxtApp()

const sidebar = useSidebar()
const isMaxSm = useMediaQuery('(max-width: 640px)')
const navBarRef = ref<MaybeElement>()
const isHover = useStaggeredHover(navBarRef, 300)

const isSettingsPageOpen = computed(() => route.name === 'settings')
</script>
